<template>
    <!-- Form Steps / Progress Bar -->
    <div>
        <ul
            class="pl-0 mx-auto text-center form-stepper form-stepper-horizontal"
        >
            <li
                v-for="currentStepIdx in totalSteps"
                :key="currentStepIdx"
                class="text-center form-stepper-list"
                :class="setState(currentStepIdx)"
                :step="currentStepIdx"
            >
                <a class="">
                    <span
                        class="form-stepper-circle"
                        :class="{'text-muted' : !isActive(currentStepIdx)}">
                            <!-- index start at 1 -->
                           <!--  <span>{{currentStepIdx}}</span> -->
                    </span>
                </a>
            </li>
        </ul>
          <span>Step {{getCorrectStepCount}} of {{totalSteps}}</span>
    </div>
</template>
<script>
export default {
    props: {
        completedSteps: {
            type: Number,
            default: 0
        },
        totalSteps: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            stepsMarkedAsCompleted: location.pathname === '/general' ? this.completedSteps : this.completedSteps + 1
        }
    },
    watch: {
        completedSteps(newValue) {
            this.stepsMarkedAsCompleted = newValue + 1
        }
    },
    computed: {
        getCorrectStepCount() {
            if(location.pathname === '/general') return 1
            return this.completedSteps + 1
        }
    },
    methods: {
        setState(index) {

            //if completed, mark completed
            if(index < this.stepsMarkedAsCompleted) {
                return 'form-stepper-completed'
            }

            //if active mark active
            if(this.isActive(index)) {
                return 'form-stepper-active'
            }

            //else mark unfinished
            return 'form-stepper-unfinished'
        },
        isActive(index) {
            return index === this.stepsMarkedAsCompleted
        }
    },
};
</script>
<style scoped>
header div {
    max-width: 600px;
    margin: auto;
}

ul.form-stepper {
    counter-reset: section;
    margin-bottom: 0;
}
ul.form-stepper .form-stepper-circle {
    position: relative;
}
ul.form-stepper .form-stepper-circle span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.form-stepper-horizontal {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
ul.form-stepper > li:not(:last-of-type) {
    margin-bottom: 0.625rem;
    -webkit-transition: margin-bottom 0.4s;
    -o-transition: margin-bottom 0.4s;
    transition: margin-bottom 0.4s;
}
.form-stepper-horizontal > li:not(:last-of-type) {
    margin-bottom: 0 !important;
}
.form-stepper-horizontal li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.form-stepper-horizontal li:not(:last-child):after {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 1px;
    content: "";
    top: 34%;
    height: 5px;
    background: #bee1c6;
}
.form-stepper-horizontal li:after {
    background-color: #dee2e6;
}
.form-stepper-horizontal li.form-stepper-completed:after {
    background-color: #5ac771;
    height: 5px;

}
.form-stepper-horizontal li:last-child {
    flex: unset;
}
ul.form-stepper li a .form-stepper-circle {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 0;
    line-height: 1.7rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.38);
    border-radius: 50%;
}

.form-stepper .form-stepper-active .label {
    color: #5ac771 !important;
}
/*.form-stepper .form-stepper-active .form-stepper-circle:hover {
    background-color: #5ac771 !important;
    color: #fff !important;
    background: url("/images/check.png") no-repeat center;
        background-size: 20px;
}*/
.form-stepper .form-stepper-unfinished .form-stepper-circle {
    background-color: #bee1c6 ;
}

.form-stepper .form-stepper-completed .label {
    color: #0e9594 !important;
}
/*.form-stepper .form-stepper-completed .form-stepper-circle:hover {
    background-color: #0e9594 !important;
    color: #fff !important;
}*/
.form-stepper .form-stepper-active span.text-muted {
    color: #fff !important;
}
.form-stepper .form-stepper-completed span.text-muted {
    color: #fff !important;
}
.form-stepper .label {
    font-size: 1rem;
    margin-top: 0.5rem;
}
.form-stepper a {
    cursor: default;
}
  @media only screen and (max-width: 600px) {
        ul.form-stepper li a .form-stepper-circle {            
            width: 21px;
            height: 21px;            
        }
  }

</style>
