<template lang="">
    <div :class="parent_div_classes">
        <div
            class="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-5 lg:text-left lg:flex lg:items-center"
        ></div>
        <div :class="child_div_classes">
            <div
                class="flex flex-wrap items-center justify-between w-full rounded sm:flex-no-wrap bg-light-overlay"
            >
                <div class="w-full px-4 mt-2 py-2 relative">
                    <img
                        :src="img_src"
                        alt="up-arrow"
                        class="mx-auto absolute"
                    />
                    <div
                        class="text-xl absolute"
                        style="margin: 2px 5px 4px 0; color: red; top:-12px; left:10px;"
                    >
                        ●
                    </div>
                    <h3
                        class="py-2 text-2xl leading-6 text-center text-white font-bold"
                    >
                        2022 Open Enrollment Ending In
                    </h3>
                    <div
                        class="overlay-progress-set flex justify-center align-center"
                    >
                        <div
                            class="progress-wrapper text-white mx-2"
                            id="progress-wrapper"
                        >
                            <!--DAYS-->
                            <counter-progress
                                :completed-steps="DD"
                                :total-steps="30"
                                :stepValue="30"
                                name="days"
                                :color="color"
                            />
                        </div>
                        <div
                            class="progress-wrapper text-white mx-2"
                            id="progress-wrapper"
                        >
                            <!--HOURS-->
                            <counter-progress
                                :completed-steps="HH"
                                :total-steps="24"
                                :stepValue="24"
                                name="hours"
                                :color="color"
                            />
                        </div>
                        <div
                            class="progress-wrapper text-white mx-2"
                            id="progress-wrapper"
                        >
                            <!--MINUTES-->
                            <counter-progress
                                :completed-steps="MM"
                                :total-steps="60"
                                :stepValue="60"
                                name="minutes"
                                :color="color"
                            />
                        </div>
                        <div
                            class="progress-wrapper text-white mx-2"
                            id="progress-wrapper"
                        >
                            <!--SECONDS-->
                            <counter-progress
                                :completed-steps="SS"
                                :total-steps="60"
                                :stepValue="60"
                                name="seconds"
                                :color="color"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CounterProgress from "./CounterProgress";
import moment from "moment";
//KNOWN CONSTANTS
/*
Used to know which time period to reduce
when a lower time period has reached 0
Ex: After hours has reached 0, reduce days
*/
const timeLabelOrderUp = {
    SS: "MM",
    MM: "HH",
    HH: "DD"
};
/*
Used to know which lower time period to reset
when a higher time period has been reduced
Ex: After reducing minutes, reet seconds
*/
const timeLabelOrderDown = {
    DD: "HH",
    HH: "MM",
    MM: "SS"
};
const timeValueResets = {
    SS: 60, //secs in min (one sec is reduced right away so shows as 59)
    MM: 59, //mins in hrs -1
    HH: 23 //hrs in days -1
    //no reset for days because no months in countdown
};
export default {
    components: {
        CounterProgress
    },
    props: {
        img_src: {
            type: String
        },
        parent_div_classes: {
            type: String
        },
        child_div_classes: {
            type: String
        },
        color: {
            type: String
        }
    },
    created() {
        //get initial values needed for each time period of the countdown
        const openEnrollmentStartDate = moment([2021, 10, 30]);
        const now = moment(moment.now());
        const { _data } = moment.duration(openEnrollmentStartDate.diff(now));
        const {
            months: MO,
            seconds: SS,
            minutes: MM,
            hours: HH,
            days: DD
        } = _data;
        console.log(`${MO} : ${DD} : ${HH} : ${MM} : ${SS}`);
        this.SS = SS;
        this.MM = MM;
        this.HH = HH;
        this.DD = DD + this.addMonth(MO);
        console.log(`${this.DD} : ${this.HH} : ${this.MM} : ${this.SS}`);
        const i = setInterval(() => this.countdown(i), 1000);
    },
    data() {
        return {
            SS: null,
            MM: null,
            HH: null,
            DD: null
        };
    },
    methods: {
        over: function() {
            return !this.DD && !this.HH && !this.MM && !this.SS;
        },
        reset: function(timeLabel) {
            this[`${timeLabel}`] = timeValueResets[timeLabel];
            if (timeLabel !== "SS") reset(timeLabelOrderDown[timeLabel]);
        },
        reduce: function(timeLabel) {
            if (this[timeLabel]) {
                this[timeLabel]--;
                //recursively reset all times lower
                return this.reset(timeLabelOrderDown[timeLabel]);
            }
            this.reduce(timeLabelOrderUp[timeLabel]);
        },
        countdown: function(i) {
            if (!this.SS) {
                // seconds ran out, reduce minute and work
                // up from there if they also ran out
                this.reduce(timeLabelOrderUp["SS"]); //reduce calls reset if needed
            }
            //this will cause a render
            this.SS--;
            if (!this.over()) return;
            clearInterval(i);
        },
        addMonth(month) {
            let days = 30; //november
            if (!month) days = 0;
            return days;
        }
    }
};
</script>
<style></style>
