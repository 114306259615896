<template>
    <radial-progress-bar
        :diameter="110"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        :stop-color="'#00008B'"
        :start-color="'#00008B'"
        :is-clockwise="true"
        :inner-stroke-width="8"
        :inner-stroke-color="'#cccccc'"
    >
        <div class="percent">
            {{ getPercent(completedSteps) }}
            <div class="completed">
                COMPLETED
            </div>
        </div>
    </radial-progress-bar>
</template>
<script>
import RadialProgressBar from "vue-radial-progress";
export default {
    methods: {
        getPercent(completedSteps) {
            let step = 100 / this.totalSteps;
            let value =
                completedSteps == 0 ? 0 : Math.ceil(step * completedSteps);
            if (value > 90) {
                this.totalSteps = 10;
                this.completedSteps = 9.6;
                value = 99;
            }
            return value + "%";
        }
    },
    components: {
        RadialProgressBar
    },
    props: {
        completedSteps: {
            type: Number,
            default: 0
        },
        totalSteps: {
            type: Number,
            default: 0
        }
    }
};
</script>
<style scoped>
.percent {
    font-size: 25px;
    font-weight: 600;
    margin-top: -9px;
}
.completed {
    font-weight: 600;
    margin-left: 1px;
    font-size: x-small;
    margin-top: -7px;
}
</style>
