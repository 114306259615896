<template>
    <div class="text-center address-section">
        <label for="email" class="mx-auto mb-4 col-sm-9 reglab mobile-title">
            <span v-if="isMobile()"> What is your current address?</span>
            <span v-else> What is your current address in {{ info.zip }}?</span>
        </label>

        <div class="mx-auto form-group col-sm-8">
            <div
                class="grid w-full grid-cols-4 mx-auto text-left mobile align-items-center"
                v-if="!show"
            >
                <label class="m-0"><span class="m-0">Address:</span></label>
                <input
                    type="text"
                    class="col-span-3 form-control address_in w-100"
                    v-on:keyup="setAsFalse"
                    v-bind:class="{ 'has-error': errorAdd || errorAddValid }"
                    v-model="info.address"
                    placeholder="address"
                    id="address"
                />
            </div>
            <div
                class="grid w-full grid-cols-4 mx-auto text-left align-items-center"
                v-if="show"
            >
                <label class="m-0"><span class="m-0">Address:</span></label>
                <input
                    type="text"
                    class="col-span-3 form-control address_in"
                    v-model="info.address"
                    v-on:keyup="setAsFalse"
                    v-bind:class="{ 'has-error': errorAdd || errorAddValid }"
                    placeholder="address"
                    id="address"
                />
            </div>
            <div
                class="grid w-full grid-cols-4 mx-auto text-left mobile align-items-center"
                v-if="errorAdd"
            >
                <label class="m-0"><span class="m-0"></span></label>
                <div v-if="errorAdd" class="errors help-block">
                    <span>Address is required!</span>
                </div>
            </div>
            <div
                class="grid w-full grid-cols-4 mx-auto text-left mobile align-items-center"
                v-if="errorAddValid"
            >
                <label class="m-0"><span class="m-0"></span></label>
                <div v-if="errorAddValid" class="errors help-block">
                    <span>Address is not valid!</span>
                </div>
            </div>
        </div>

        <div class="mx-auto form-group col-sm-8" v-if="!show">
            <div
                class="grid w-full grid-cols-4 mx-auto text-left mobile align-items-center"
            >
                <label class="m-0"><span class="m-0">City/State:</span></label>
                <p class="col-span-3 m-0 text-xl font-semibold">
                    {{ info.city }}, {{ info.state }}
                </p>
            </div>
        </div>

        <div class="mx-auto form-group col-sm-8" v-if="!show">
            <div class="grid grid-cols-4 text-left align-items-center">
                <label class="m-0">
                    <span class="m-0">Zip:</span>
                </label>
                <div class="col-span-3">
                    <div class="flex align-items-center">
                        <div class="inline-block mr-2">
                            <span class="text-lg text-xl">{{ info.zip }}</span>
                        </div>
                        <button
                            class="text-2xl bg-blue-200 btn btn-info"
                            @click="show = !show"
                        >
                            Change
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="clearfix form-group col-sm-8" v-else>
            <div class="grid grid-cols-4 text-left align-items-center">
                <label class="m-0">
                    <span class="m-0">City:</span>
                </label>
                <input
                    type="text"
                    v-model="info.city"
                    class="col-span-3 form-control"
                    v-on:keyup="cleanCity"
                    v-bind:class="{ 'has-error': errorCity }"
                    @keydown="nameKeydown($event)"
                />
            </div>

            <div
                class="grid w-full grid-cols-4 mx-auto text-left mobile align-items-center"
                v-if="errorCity"
            >
                <label class="m-0"><span class="m-0"></span></label>
                <div class="errors help-block">
                    <span>City is not valid</span>
                </div>
            </div>

            <div class="grid grid-cols-4 text-left mt-3 align-items-center">
                <label class="pr-4 m-0 md:pr-0" for="select">
                    <span class="m-0">State:</span>
                </label>
                <div class="grid grid-cols-5 col-span-3 align-items-center">
                    <div class="relative col-span-2">
                        <div class="position-relative">
                            <select
                                class="block float-none px-2 py-1 pl-1 pr-4 mb-0 text-gray-700 bg-gray-100 border rounded appearance-none w-100 border-brand lg:float-right lg:col-span-3 md:col-span-3 focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="info.state"
                                id="select"
                            >
                                <option
                                    v-for="option in schema.fields[2].values"
                                    :key="option"
                                    >{{ option }}</option
                                >
                            </select>
                            <div
                                class="absolute inset-y-0 top-0 bottom-0 right-0 flex items-center pr-1 text-gray-700 pointer-events-none"
                            >
                                <svg
                                    class="w-4 h-4 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <label class="col-span-1 m-0 text-center" for="zip">
                        <span class="m-0">Zip:</span>
                    </label>
                    <div class="col-span-2 ml-2 text-left">
                        <input
                            v-model="info.zip"
                            maxLength="5"
                            type="number"
                            step="1"
                            min="0"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            oninput="javascript:if (this.value.length > 4) this.value = this.value.slice(0, this.maxLength);"
                            v-on:keyup="cleanZip"
                            class="w-100 form-control"
                            v-bind:class="{ 'has-error': errorZip }"
                        />

                        <div v-if="errorZip" class="errors help-block">
                            <span>Zip Code is not valid</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {},
    data() {
        let urlParams = new URLSearchParams(window.location.search);
        let address = urlParams.get("address");
        return {
            show: false,
            erroraddress: false,
            errorvalidaddress: false,
            errorcity: false,
            errorzip: false,
            info: {
                city: "",
                state: "",
                address:
                    urlParams.get("address") || urlParams.get("contact.street")
                        ? urlParams.get("address") ||
                          urlParams.get("contact.street")
                        : "",
                zip: ""
            }
        };
    },
    props: {
        schema: {
            type: Object,
            default: () => ({})
        },
        model: {
            type: Object,
            default: undefined
        },
        zip: {
            type: String
        }
    },
    methods: {
        nameKeydown(e) {
            if (/^\W$/.test(e.key) || !isNaN(e.key)) {
                e.preventDefault();
            }
        },
        setAsFalse() {
            this.$props.model.erroraddress = false;
        },
        cleanCity() {
            this.$props.model.errorcity = false;
            this.$props.model.city = this.info.city;
        },
        cleanZip() {
            this.setZip();
            this.$props.model.errorzip = false;
            this.$props.model.zip = this.info.zip;
        },
        setZip() {
            this.$props.model.zip = this.info.zip;
            console.log(this.$props.model.zip);
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    created() {
        const props = this.$props.model;
        this.info.city = this.$props.model.city;
        this.info.state = this.$props.model.state;
        this.info.zip = this.$props.model.zip;

        setInterval(() => {
            this.$emit("updatelocation", this.info);
        }, 500);
    },
    mounted: function() {
        (this.erroraddress = this.$props.model.erroraddress),
            (this.$props.model.zip = this.info.zip);
    },
    computed: {
        errorAdd: function() {
            // `this` points to the vm instance
            //this.errorAddForm
            return this.$props.model.erroraddress;
        },
        errorAddValid: function() {
            // `this` points to the vm instance
            //this.errorAddForm
            return this.$props.model.erroraddressvalid;
        },
        // a computed getter
        errorCity: function() {
            return this.$props.model.errorcity;
        },
        errorZip: function() {
            return this.$props.model.errorzip;
        }
    }
};
</script>
<style scoped>
.border-brand {
    border: 1px solid lightgrey !important;
}
.mt-state {
    margin-top: 1.1rem;
    margin-left: 0.5rem;
}
.zip-align {
    margin-top: 13px;
    margin-right: 53px;
}
.state-align {
    margin-top: 13px;
    margin-left: 14%;
}
.zip {
    width: 60%;
}
.col-md-location {
    flex: 0 0 52%;
    max-width: 52%;
}
@media only screen and (max-width: 600px) {
    .mobile_zip_label {
        padding-left: 2.5rem !important;
    }
    .city_state_mobile {
        margin-top: 0.5rem !important;
    }
    div.mobile label {
        margin-right: 46px;
        width: 23px !important;
    }
    .mobile_no_left_margin {
        margin-left: 0px !important;
    }
    .col-md-location {
        flex: 0 0 51%;
        max-width: 51%;
    }
}

@media only screen and (max-width: 500px) {
    .col-md-location {
        flex: 0 0 91%;
        max-width: 91%;
    }
}
</style>
