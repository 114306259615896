/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
const axios = require("axios");
window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component(
    "example-component",
    require("./components/ExampleComponent.vue").default
);
Vue.component(
    "medskip-location-form",
    require("./components/LocationForm.vue").default
);
Vue.component(
    "circular-progress",
    require("./components/CircularProgress.vue").default
);
Vue.component(
    "step-progress",
    require("./components/StepProgress.vue").default
);
Vue.component(
    "counter-progress",
    require("./components/CounterProgress.vue").default
);
Vue.component("countdown", require("./components/Countdown.vue").default);
//global registration
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import VueTelInput from "vue-tel-input";
import TelInputField from "./components/tel-input-field.vue";
import TelInputField1 from "./components/tel-input-field-1.vue";


const allphones = require("./components/phones.json");

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.use(VueTelInput);
Vue.use(VueFormGenerator);

Vue.component("field-tel-input", TelInputField);
Vue.component("field-tel-input-1", TelInputField1);

VueFormGenerator.validators.customemail = function(value, field, model) {
    var re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    var out = re.test(value);

    if (!out) {
        return ["Email is not valid"];
    }
    return [];
};

VueFormGenerator.validators.dropdown = function(value, field, model) {
    if (value == "DD" || value == "MM") {
        return ["Date of birth is not valid"];
    }
    return [];
};

function uet_report_conversion() {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "step4", {});
}

//TO DEBUG CHANGE HERE
// const DEBUG_MODE = true;
let DEBUG_MODE = false;

const BACKSPACE = 8; //keycode
const MEDICARE_AGE = 65;
let MEDICARE_FORM_LENGTH = 4;
let HEALTHCARE_FORM_LENGTH = 6;

//to get to medicare form quickly
//with test values change here

//medicare flow
let FORM_COMPLETED_START = DEBUG_MODE ? MEDICARE_FORM_LENGTH : 1;

//if general flow add a step because step 1 is zipcode
let GENERAL_FLOW = location.href.indexOf('general') !== -1
if(GENERAL_FLOW) {
    MEDICARE_FORM_LENGTH = 5
    HEALTHCARE_FORM_LENGTH = 7
}

//healthcare flow
// const FORM_COMPLETED_START = DEBUG_MODE ? HEALTHCARE_FORM_LENGTH : 1;

const ONE_FORM_STEP = 1;
const TWO_FORM_STEP = 2;
const CURRENT_YEAR = new Date().getFullYear();

let urlParams = new URLSearchParams(window.location.search);
let date_of_birth = urlParams.get("dob")
    ? urlParams.get("dob")
    : urlParams.get("contact.dob_month/contact.dob_day/contact.day_year");
var res = date_of_birth ? date_of_birth.split("/") : "";
let dobmonth = res[0] ? res[0] : "";
let dobday = res[1] ? res[1] : "";
let dobyear = res[2] ? res[2] : "";
let address =
    urlParams.get("address") || urlParams.get("contact.street")
        ? urlParams.get("address") || urlParams.get("contact.street")
        : "";
// let gender = urlParams.get("gender")
//     ? urlParams.get("gender")
//     : urlParams.get("$gender$") || "";
// if (gender) {
//     gender = gender.toLowerCase();
//     gender = gender == "female" ? "F" : "M";
// }
let firstname = urlParams.get("first_name")
    ? urlParams.get("first_name")
    : urlParams.get("contact.first_name");
let lastname = urlParams.get("last_name")
    ? urlParams.get("last_name")
    : urlParams.get("contact.last_name");
let email = urlParams.get("email");
let phone =
    urlParams.get("primary_phone") || urlParams.get("contact.phone_cell")
        ? urlParams.get("primary_phone") || urlParams.get("contact.phone_cell")
        : "";
//function must return (304) 849-2404
if (phone) {
    phone = formatPhoneNumber(phone);
}

var phone1 = phone ? phone.match(/\(([^)]+)\)/)[1] : "";
let phone2 = phone ? phone.split(/ (.+)-/)[1] : "";
let phone3 = phone ? phone.split(/-(.+)/)[1] : "";
let household = urlParams.get("household_size");
let income = urlParams.get("household_income");
let age = urlParams.get("age");
// let zip = urlParams.get('{zip}') ? urlParams.get('{zip}') : urlParams.get('$zip');
let segm = urlParams.get("$segm$");
if (
    segm == "Yes" ||
    segm == "yes" ||
    segm == "y" ||
    segm == "Y" ||
    segm == "No" ||
    segm == "no" ||
    segm == "n" ||
    segm == "N"
) {
    household =
        segm == "Yes" || segm == "yes" || segm == "y" || segm == "Y"
            ? "3"
            : "1";
}
let showme = urlParams.get("showme") ? urlParams.get("showme") : "";

if (window.location.pathname.includes("partsab")) {
    localStorage.setItem("page", "partsab");
} else if (window.location.pathname.includes("dental")) {
    localStorage.setItem("page", "partsab");
} else if (window.location.pathname == "/") {
    localStorage.setItem("page", "other");
}

const boberdooSrc =
    localStorage.getItem("page") == "partsab"
        ? "HQ_SimpleMedAdv"
        : "HQ_SimpleMedCov";
/*var getGeoData = function(callback) {
    $.ajax({
        url: "https://geoip-db.com/jsonp",
        jsonpCallback: "callback",
        dataType: "jsonp",
        success: callback
    });
};
// Call the getGeoData function with callback
getGeoData(function(location) {
    var ip = location.IPv4;
    // return ip;
    localStorage.setItem("user_ipv4", ip);
});*/
// Switched to ipinfo
var getGeoData = function(callback) {
    $.ajax({
        url: "https://ipinfo.io/json?token=3c2d1fd8d3a4a9",
        jsonpCallback: "callback",
        dataType: "jsonp",
        success: callback
    });
};
getGeoData(function(location) {    
      localStorage.setItem("user_ipv4", location.ip);
});

new Vue({
    el: "#app",
    data: {
        model: {
            dob_month: dobmonth || (DEBUG_MODE ? "01" : ""),
            dob_day: dobday || (DEBUG_MODE ? "01" : ""),
            dob_year: dobyear || 1956,
            first_name: firstname || (DEBUG_MODE ? "test" : ""),
            last_name: lastname || (DEBUG_MODE ? "test" : ""),
            city: "Corona", //change?
            citygoogle: "",
            state: "CA",
            zip: DEBUG_MODE ? "98111" : "",
            wrongaddress: true,
            address: "",
            household: household || 2,
            income: income || "40000",
            gender: getUrlVars()["gender"] || "M",
            currentlyEnrolled: "",
            email: email || (DEBUG_MODE ? "test@test.com" : ""),
            addresserror: false,
            errorday: false,
            errormonth: false,
            errorcity: false,
            errorzip: false,
            errorphone: false,
            errorfirst: false,
            errorlast: false,
            erroremail: false,
            errorphone1: false,
            errorphone2: false,
            errorphone3: false,
            phone1: phone1 || (DEBUG_MODE ? "208" : ""),
            phone2: phone2 || (DEBUG_MODE ? "840" : ""),
            phone3: phone3 || (DEBUG_MODE ? "2345" : ""),
            erroraddress: false,
            erroraddressvalid: false,
            ip_address:
                localStorage.getItem("user_ipv4") ||
                "no ip address in local storage smc",
            ip_address_error:
                localStorage.getItem("ip_address_error") ||
                "no ip_error in local storage smc",
            tobacco: "",
            self_employed: "",
            feet: "",
            weight: "",
            pregnant: "",
            medical: "",
            universal_leadid: "",
            trusted_form_token: "",
            trusted_form_url: "",
            source: "",
            affiliate: "a",
            creative: "",
            redirect_db: "https://simplemedicarecoverage.com",
            requestid: "",
            oid: "",
            is_robly: "",
            offer: "",
            tfn: "",
            arcamax_signup: "",
            boberdoo_src: boberdooSrc,
            source1: "",
            source2: "",
            source3: "",
            source4: "",
            source5: "",
            showme: showme || ""
        },
        ten: false,
        formValues: {
            years: [
                "YYYY",
                "1921",
                "1922",
                "1923",
                "1924",
                "1925",
                "1926",
                "1927",
                "1928",
                "1929",
                "1930",
                "1931",
                "1932",
                "1933",
                "1934",
                "1935",
                "1936",
                "1937",
                "1938",
                "1939",
                "1940",
                "1941",
                "1942",
                "1943",
                "1944",
                "1945",
                "1946",
                "1947",
                "1948",
                "1949",
                "1950",
                "1951",
                "1952",
                "1953",
                "1954",
                "1955",
                "1956",
                "1957",
                "1958",
                "1959",
                "1960",
                "1961",
                "1962",
                "1963",
                "1964",
                "1965",
                "1966",
                "1967",
                "1968",
                "1969",
                "1970",
                "1971",
                "1972",
                "1973",
                "1974",
                "1975",
                "1976",
                "1977",
                "1978",
                "1979",
                "1980",
                "1981",
                "1982",
                "1983",
                "1984",
                "1985",
                "1986",
                "1987",
                "1988",
                "1989",
                "1990",
                "1991",
                "1992",
                "1993",
                "1994",
                "1995",
                "1996",
                "1997",
                "1998",
                "1999",
                "2000",
                "2001"
            ],
            months: [
                "MM",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12"
            ],
            days: [
                "DD",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31"
            ],
            incomes: ["15000", "30000", "40000", "50000"],
            households: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        },
        formOptions: {
            validationErrorClass: "has-error",
            validationSuccessClass: "has-success",
            validateAfterChanged: true
        },
        dobTabSchema: {
            fields: [
                {
                    type: "select",
                    label: "",
                    model: "dob_month",
                    required: true,
                    //                    validator: VueFormGenerator.validators.dropdown,
                    validator: VueFormGenerator.validators.dropdown,
                    values: [
                        "MM",
                        "01",
                        "02",
                        "03",
                        "04",
                        "05",
                        "06",
                        "07",
                        "08",
                        "09",
                        "10",
                        "11",
                        "12"
                    ],
                    styleClasses: "col-xs-6 dob dob_month",
                    selectOptions: {
                        noneSelectedText: " "
                    }
                },
                {
                    type: "select",
                    label: "",
                    model: "dob_day",
                    required: true,
                    validator: VueFormGenerator.validators.dropdown,
                    values: [
                        "DD",
                        "01",
                        "02",
                        "03",
                        "04",
                        "05",
                        "06",
                        "07",
                        "08",
                        "09",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                        "24",
                        "25",
                        "26",
                        "27",
                        "28",
                        "29",
                        "30",
                        "31"
                    ],
                    styleClasses: "col-xs-6 dob dob_day",
                    selectOptions: {
                        noneSelectedText: " "
                    }
                },
                {
                    type: "select",
                    label: "",
                    model: "dob_year",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    values: [
                        "1921",
                        "1922",
                        "1923",
                        "1924",
                        "1925",
                        "1926",
                        "1927",
                        "1928",
                        "1929",
                        "1930",
                        "1931",
                        "1932",
                        "1933",
                        "1934",
                        "1935",
                        "1936",
                        "1937",
                        "1938",
                        "1939",
                        "1940",
                        "1941",
                        "1942",
                        "1943",
                        "1944",
                        "1945",
                        "1946",
                        "1947",
                        "1948",
                        "1949",
                        "1950",
                        "1951",
                        "1952",
                        "1953",
                        "1954",
                        "1955",
                        "1956",
                        "1957",
                        "1958",
                        "1959",
                        "1960",
                        "1961",
                        "1962",
                        "1963",
                        "1964",
                        "1965",
                        "1966",
                        "1967",
                        "1968",
                        "1969",
                        "1970",
                        "1971",
                        "1972",
                        "1973",
                        "1974",
                        "1975",
                        "1976",
                        "1977",
                        "1978",
                        "1979",
                        "1980",
                        "1981",
                        "1982",
                        "1983",
                        "1984",
                        "1985",
                        "1986",
                        "1987",
                        "1988",
                        "1989",
                        "1990",
                        "1991",
                        "1992",
                        "1993",
                        "1994",
                        "1995",
                        "1996",
                        "1997",
                        "1998",
                        "1999",
                        "2000",
                        "2001"
                    ],
                    styleClasses: "col-xs-6 dob dob_year",
                    selectOptions: {
                        noneSelectedText: "YYYY"
                    }
                }
            ]
        },
        genderTabSchema: {
            fields: [
                {
                    type: "radios",
                    label: "What is your Gender?<span style='font-size:15px;font-weight: 300;display: block;'>(Optional)</span>",
                    model: "gender",
                    //                values: ["Male", "Female"],
                    values: [
                        { name: "Male", value: "M" },
                        { name: "Female", value: "F" }
                    ],
                    required: false,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "gender_radio flex"
                }
            ]
        },
        // currentlyEnrolledTabSchema: {
        //     fields: [
        //         {
        //             type: "radios",
        //             label:
        //                 "Are you currently enrolled in Medicare and Medicaid?",
        //             model: "currentlyEnrolled",
        //             //                values: ["Male", "Female"],
        //             values: [
        //                 { name: "Yes ", value: "Advantage" },
        //                 { name: "No", value: "Supplement" },
        //                 { name: "I don’t know", value: "Unknown" }
        //             ],
        //             required: false,
        //             validator: VueFormGenerator.validators.string,
        //             styleClasses: "gender_radio flex currently_enrolled"
        //         }
        //     ]
        // },
        householdTabSchema: {
            fields: [
                {
                    type: "select",
                    required: false,
                    label: "What is your household size?",
                    model: "household",
                    values: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-xs-6 dob",
                    selectOptions: {
                        hideNoneSelectedText: true
                    }
                }
            ]
        },
        houseIncomeTabSchema: {
            fields: [
                {
                    type: "select",
                    required: false,
                    model: "income",
                    values: function() {
                        return [
                            { id: "15000", name: "$0 to $14999" },
                            { id: "30000", name: "$15000 to $30000" },
                            { id: "40000", name: "$30001 to $40000" },
                            { id: "50000", name: "$40001 and over" }
                        ];
                    },
                    default: "40000",
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-xs-6 dob dob_day mb-0",
                    selectOptions: {
                        hideNoneSelectedText: true
                    }
                }
            ]
        },
        secondTabSchema: {
            fields: [
                {
                    type: "input",
                    inputType: "text",
                    label: "First Name",
                    placeholder: "First",
                    model: "first_name",
                    min: 2,
                    required: true,
                    //                    validator: VueFormGenerator.validators.string,
                    validator: VueFormGenerator.validators.string.locale({
                        fieldIsRequired: "First Name is not valid"
                    }),
                    styleClasses: "firstname"
                },
                {
                    type: "input",
                    inputType: "text",
                    label: "Last Name",
                    placeholder: "Last",
                    model: "last_name",
                    min: 2,
                    required: true,
                    validator: VueFormGenerator.validators.string.locale({
                        fieldIsRequired: "Last Name is not valid"
                    }),
                    styleClasses: "lastname"
                },
                {
                    type: "input",
                    inputType: "text",
                    label: "Email",
                    model: "email",
                    required: true,
                    validator: VueFormGenerator.validators.customemail,
                    styleClasses: "emailaddress col-lg-12 col-md-12 "
                }
                //                {
                //                    type: "tel-input",
                //                    label: "Phone",
                //                    model: "phone1",
                //                    placeholder: "###",
                //                    max:3,
                //                    maxlength:3,
                //                    required: true,
                //                    validator: VueFormGenerator.validators.string.locale({
                //                        fieldIsRequired: "Phone Number is not valid",
                //                    }),
                //                    styleClasses: 'phonenumber col-md-12 mb-3',
                //                    phstyles: 'float-left mr-3 form-control '
                //                },
                //                {
                //                    type: "tel-input-1",
                //                    label: "",
                //                    model: "phone2",
                //                    placeholder: "###",
                //                    max:3,
                //                    maxlength:3,
                //                    required: true,
                //                    validator: VueFormGenerator.validators.string.locale({
                //                        fieldIsRequired: "Phone Number is not valid",
                //                    }),
                //                    styleClasses: 'phonenumber col-md-12 phone2',
                //                    phstyles: 'float-left mr-3 form-control '
                //                },
                //                {
                //                    type: "input",
                //                    inputType: "text",
                //                    label: "",
                //                    model: "phone3",
                //                    placeholder: "####",
                //                    max:4,
                //                    maxlength:4,
                //                    required: true,
                //                    validator: VueFormGenerator.validators.string.locale({
                //                        fieldIsRequired: "Phone Number is not valid",
                //                    }),
                //                    styleClasses: 'phonenumber col-md-12 phone2',
                //                    phstyles: 'float-left mr-3 form-control '
                //                }
            ]
        },
        thirdTabSchema: {
            fields: [
                {
                    type: "input",
                    inputType: "text",
                    label: "Address",
                    model: "street",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-lg-6 col-xs-12 "
                },
                {
                    type: "input",
                    inputType: "text",
                    label: "City",
                    model: "city",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-lg-3 col-xs-12"
                },
                {
                    type: "select",
                    label: "State",
                    model: "state",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    values: [
                        "AL",
                        "AK",
                        "AS",
                        "AZ",
                        "AR",
                        "CA",
                        "CO",
                        "CT",
                        "DE",
                        "DC",
                        "FM",
                        "FL",
                        "GA",
                        "GU",
                        "HI",
                        "ID",
                        "IL",
                        "IN",
                        "IA",
                        "KS",
                        "KY",
                        "LA",
                        "ME",
                        "MH",
                        "MD",
                        "MA",
                        "MI",
                        "MN",
                        "MS",
                        "MO",
                        "MT",
                        "NE",
                        "NV",
                        "NH",
                        "NJ",
                        "NM",
                        "NY",
                        "NC",
                        "ND",
                        "MP",
                        "OH",
                        "OK",
                        "OR",
                        "PW",
                        "PA",
                        "PR",
                        "RI",
                        "SC",
                        "SD",
                        "TN",
                        "TX",
                        "UT",
                        "VT",
                        "VI",
                        "VA",
                        "WA",
                        "WV",
                        "WI",
                        "WY"
                    ],
                    styleClasses: "col-lg-3 col-xs-12",
                    selectOptions: {
                        noneSelectedText: "Select a State"
                    }
                }
            ]
        },
        fourthTabSchema: {
            fields: [
                {
                    type: "input",
                    inputType: "text",
                    label: "Email Address",
                    model: "email",
                    required: true,
                    validator: VueFormGenerator.validators.email,
                    styleClasses: "emailaddress col-lg-12 col-md-12 "
                },
                {
                    type: "tel-input",
                    label: "Phone Number",
                    model: "phone",
                    placeholder: "(###) ### - ####",
                    styleClasses: "phonenumber col-md-12 ",
                    phstyles: "form-control col-md-6 mx-auto"
                }
            ]
        },
        completedSteps: FORM_COMPLETED_START, //*****change here to test a different part of form******
        notActive: true,
        totalSteps:
            window.location.pathname.indexOf("health") === -1
                ? MEDICARE_FORM_LENGTH
                : HEALTHCARE_FORM_LENGTH,
        medicare: window.location.pathname.includes("medicare")
            ? "medicare"
            : "",
        healthcare: window.location.href.includes("healthcare"),
        flow: window.location.pathname
            .split("/")
            .find(string => string === "medicare" || string === "healthcare"),
        floww: "",
        back: "Back",
        transitionName: "bounce",
        last: false,
        stepAmount: 1,
        medicareQuestionStep: 3
    },
    computed: {
        errorFirst: function() {
            return this.model.errorfirst;
        },
        errorLast: function() {
            return this.model.errorlast;
        },
        errorEmail: function() {
            return this.model.erroremail;
        },
        errorPhone1: function() {
            return this.model.errorphone1;
        },
        errorPhone2: function() {
            return this.model.errorphone2;
        },
        errorPhone3: function() {
            return this.model.errorphone3;
        },
        errorPhone: function() {
            return this.model.errorphone;
        }
    },
    watch: {
        first_name(val) {
            console.log("idemoo");
            this.model.first_name = val.replace(/\W/g, "");
        }
    },
    created() {
        this.getGeoInfo(); //get city name from zip

        if (this.medicare == "medicare") {
            // this.model.dobyear = "1956";
            if (
                !urlParams.get("gender") ||
                (urlParams.get("gender").toLowerCase() != "male" &&
                    urlParams.get("gender").toLowerCase() != "female")
            ) {
                this.model.gender = "";
            }
            if (this.validateDobOncreate()) {
                if (!this.validateGenderOncreate()) this.model.gender = "M";
            }
        } else {
            if (this.validateDobOncreate()) {
                if (!this.validateGenderOncreate()) {
                    this.model.gender = "M";
                }
                if (this.validateHouseholdOncreate()) {
                    // alert('true');
                } else {
                    this.model.household = 2;
                }
                if (this.validateIncomeOnCreate()) {
                    // this.completedSteps = 5;
                } else {
                    this.model.income = 40000;
                }
            }
        }

        changeButtonId(this.completedSteps);

        this.model.zip = this.getQueryVariable("zipcode");
         let model = this.model;
        let paramsObject  = JSON.parse(localStorage.getItem('params')); 
        
            let uri = window.location.href.split("?");
            if (uri.length == 2) {
                let vars = uri[1].split("&");
                let getVars = {};
                let tmp = "";
                //if new paramaters on origin
                if (vars.length > 4) {
                   
                    //model.requestid = paramsObject.r
                    vars.forEach(function(v) {
                        tmp = v.split("=");
                        if (tmp.length == 2) {
                            getVars[tmp[0]] = tmp[1];
                            if (tmp[0] == "a") {
                                model.affiliate =  tmp[1];
                            }
                            if (tmp[0] == "c") {
                                model.creative = tmp[1];
                            }
                            if (tmp[0] == "s1") {
                                model.source1 =  tmp[1];
                            }
                            if (tmp[0] == "s2") {
                                model.source2 = tmp[1];
                            }
                            if (tmp[0] == "s3") {
                                model.source3 = tmp[1];
                            }
                            if (tmp[0] == "s4") {
                                model.source4 =  tmp[1];
                            }
                            if (tmp[0] == "s5") {
                                model.source5 =  tmp[1];
                            }
                            if (tmp[0] == "r") {
                                model.requestid =  tmp[1];
                            }
                            if (tmp[0] == "oid") {
                                model.oid =  tmp[1];
                            }
                        }
                    });
                } 
                // else use the localstorage from Iframe
                else {


                model.affiliate =  paramsObject.a
                            
            
                model.creative = paramsObject.c;
            
                model.source1 =  paramsObject.s1;
            
                model.source2 = paramsObject.s2;
            
                model.source3 = paramsObject.s3;
            
                model.source4 =  paramsObject.s4;
           
                model.source5 =  paramsObject.s5;
            
                model.requestid =  paramsObject.r;
            
                model.oid =  paramsObject.oid;         

            
                }
                
                
                // do
            

        }
        this.model = model;
        //this.getGeoInfo();
    },
    mounted() {       
        this.passIframe();
         const campaignKey = '671be4541968e76cce1a85f50a32c359';
        var a=document.createElement("script");
        a.type="text/javascript";a.async=!0;
        a.defer=!0;a.src=document.location.protocol+"//dist.routingapi.com/jsapi/v1/retreaver.min.js";
        const vm = this
        a.onload=a.onreadystatechange=function(){
            Retreaver.Base.Cookies.set('CallPixels-ou', '');
            Retreaver.configure({host:"api.routingapi.com",prefix:"https:"==document.location.protocol?"https":"http"});
            // console.log(vm.model.income)
            var rcampaign = new Retreaver.Campaign({campaign_key: campaignKey});
            rcampaign.auto_replace_numbers(function (number) {
                vm.number = number
              //  vm.getTags()
            });
        };
        (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(a)
    },
    methods: {
        isHealthCareFlow: function() {
            return this.medicare === "";
        },
        getTags(){
          
            const universal_leadid_el = document.getElementById("leadid_token");
            const universal_leadid_v = universal_leadid_el
                ? universal_leadid_el.value
                : "";
            const trusted_form_url_el = document.getElementById(
                "xxTrustedFormCertUrl_0"
                );
            const trusted_form_url_v = trusted_form_url_el
                    ? trusted_form_url_el.value
                    : "";

            var bday = this.model.dob_year + "-" + this.model.dob_month + "-" + this.model.dob_day;
            const age = this.getAge(bday).years;
            
            // console.log(age, this.model.agegroup, this.model.income);
            const dob = this.model.dob_day + '/' + this.model.dob_month + '/' + this.model.dob_year;
            
            let userGender = '';
            if(this.model.gender) {
                userGender = this.model.gender == 'M' ? 'male' : 'female';
            }                        
            this.number.replace_tags({
                state: this.model.state,
                first_name: this.model.first_name,
                last_name: this.model.last_name,
                phone: this.model.phone,
                address: this.model.address,
                city: this.model.city,
                email: this.model.email,
                zip: this.model.zip,
                gender: userGender,
                household: this.model.household,
                income: this.model.income,
                qualified_event: this.model.thingsHappened,
                agegroup2: this.model.agegroup,
                age: age,
                dobt: dob,
                currentlyhas_medicare: this.model.currentlyEnrolled,
                jornaya_id: universal_leadid_v,
                trusted_form: trusted_form_url_v,
            });
        },
        isMedicareFlow: function() {
            return this.medicare === "medicare";
        },
        isNewStep: function() {
            return this.completedSteps === 3;
        },
        getNewStep: function() {
            return 3;
        },
        selectionCheckboxClicked: function(ev) {
            console.log("sdf");
            var element = document.getElementById(".errors.help-block");
            element.classList.add("hide");

            var element = document.getElementById("zipcode");
            element.classList.remove("red-borde");
        },
        sha256: async function(toBeHashed) {
            // encode as UTF-8
            const msgBuffer = new TextEncoder().encode(toBeHashed);                    

            // hash the var
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

            // convert ArrayBuffer to Array
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            // convert bytes to hex string                  
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            // console.log(hashHex)
            return hashHex;
        },
        nameKeydown(e) {
            if (/^\W$/.test(e.key) || !isNaN(e.key)) {
                e.preventDefault();
            }
        },
        NumberLimit3(e) {
            console.log(e.key);
        },
        NumberLimit4(e) {
            console.log(e.key);
        },
        clearDay: function() {
            this.model.errorday = false;
        },
        clearMonth: function() {
            this.model.errormonth = false;
        },
        clearFirst: function() {
            this.model.errorfirst = false;
        },
        clearLast: function() {
            this.model.errorlast = false;
        },
        clearPhone1: function() {
            this.model.errorphone1 = false;
            this.model.errorphone = false;
        },
        clearPhone2: function() {
            this.model.errorphone2 = false;
            this.model.errorphone = false;
        },
        clearPhone3: function() {
            this.model.errorphone3 = false;
            this.model.errorphone = false;
        },
        clearEmail: function() {
            this.model.erroremail = false;
        },
        errorAddAndCityForm: function() {
            if (
                this.model.city != "" &&
                this.model.zip != "" &&
                this.model.address != ""
            ) {
                this.model.errorcity = false;
                this.model.errorzip = false;

                // var re = /^(([a-zA-Z]){2})/;
                this.model.erroraddress = false;
                var patt = new RegExp("^(?!s*$).+");
                var valid = patt.test(this.model.address);
                if (valid) {
                    this.model.erroraddressvalid = false;
                    return true;
                }
                this.model.erroraddressvalid = true;
                return false;
            }

            if (this.model.address == "") {
                this.model.erroraddress = true;
            } else {
                this.model.erroraddress = false;
            }

            if (this.model.city == "") {
                this.model.errorcity = true;
            } else {
                this.model.errorcity = false;
            }

            if (this.model.zip == "") {
                this.model.errorzip = true;
            } else {
                this.model.errorzip = false;
            }

            return false;
        },
        errorAddForm: function() {},
        checkValidPhone(number) {
            if (allphones.includes(number)) {
                this.model.errorphone = false;
                return true;
            }

            this.model.errorphone = true;
            return false;
        },
        getRandomInt: function(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        skipStep: function(event) {
            this.completedSteps++;
            event.target.id = "step" + this.completedSteps;
        },
        passIframe(){
            window.addEventListener('message', function (event) {
                const items = event.data
                var iframeParams = Object.fromEntries(new URLSearchParams(items))
               if (iframeParams.r) {
                localStorage.setItem('params',JSON.stringify(iframeParams)) 
                }            
            });    
        },
        
        nextStep: function(event) {            
            window.scrollTo(0, 0);
           //  this.getTags();
             this.transitionName = "bounce";     

            //first step check date and get city name from zip
            if (this.completedSteps === FORM_COMPLETED_START) {
                if (!this.validateDate()) return;
                //get dob
                var bday =
                    this.model.dob_year +
                    "-" +
                    this.model.dob_month +
                    "-" +
                    this.model.dob_day;

                //get age from dob
                var age = this.getAge(bday);                
                localStorage.setItem("age", age.years);


                //if user did not input date, get it from years
                //TODO: check if user picks YYYY option
                if (!age.years) {
                    age.years = CURRENT_YEAR - this.model.dob_year;
                }

                var newUrlIS;
                let str = window.location.href;
                let afterQuestionMark = str.split("?")[1];
                //console.log(afterQuestionMark);

                //take out type param if there in case user
                //changes dob after pressing the back button
                afterQuestionMark = afterQuestionMark.replace(
                    "&type=medicare&agegroup=medicare",
                    ""
                );
                afterQuestionMark = afterQuestionMark.replace(
                    "&type=healthcare&agegroup=dual",
                    ""
                );
                let agegroup;

                //check if medicare of healthcare
                if (age.years < MEDICARE_AGE) {
                    this.flow = "healthcare";
                    this.medicare = "";
                    agegroup = 'dual';
                    this.totalSteps = HEALTHCARE_FORM_LENGTH;
                    newUrlIS =
                        window.location.origin +
                        "/healthcare?" +
                        afterQuestionMark +
                        "&type=healthcare&agegroup=dual";
                } else if (age.years >= MEDICARE_AGE) {
                    this.flow = "medicare";
                    this.medicare = "medicare";
                    this.totalSteps = MEDICARE_FORM_LENGTH;
                    agegroup = 'medicare';
                    newUrlIS =
                        window.location.origin +
                        "/medicare?" +
                        afterQuestionMark +
                        "&type=medicare&agegroup=medicare";
                }
                 this.model.agegroup = agegroup;

                //puts new url in search and navigates to page
                history.pushState({}, null, newUrlIS);
            }

            // get current step
             const buttonNum = Number(this.completedSteps)
             let buttonStep;
              // Add string according to GTM              
              if (buttonNum === 1) {
                     buttonStep = 'button' + buttonNum + '-click' ;
                } else {
                      buttonStep = 'button' + buttonNum + '-click-' +  this.flow ;
                }
              console.log(buttonStep);
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({ event: buttonStep })

            //check if it is the step before the medicare question
            if (this.completedSteps === this.medicareQuestionStep - 1) {
                //get dob
                var bday =
                    this.model.dob_year +
                    "-" +
                    this.model.dob_month +
                    "-" +
                    this.model.dob_day;

                //get age from dob
                var age = this.getAge(bday);

                //if user did not input date, get it from years
                //TODO: check if user picks YYYY option
                if (!age.years) {
                    age.years = CURRENT_YEAR - this.model.dob_year;
                }

                //if under 65 years skip medicare question step
                if (this.flow === "medicare") {
                    this.stepAmount =
                        age.years >= 65 ? ONE_FORM_STEP : TWO_FORM_STEP;
                }

                //go up necessary steps
                this.completedSteps += this.stepAmount;

                /*
                 WARNING:
                 these lines are what's making the id's different
                 than what they are in the codebase
                 because these lines overwrite the id dynamically
                 */
                event.target.id = "step" + this.completedSteps;
                return;
            }

            //check if address form step
           if (
                (this.completedSteps === MEDICARE_FORM_LENGTH - ONE_FORM_STEP &&
                    this.medicare === "medicare") ||
                (this.completedSteps ===
                    HEALTHCARE_FORM_LENGTH - ONE_FORM_STEP &&
                    this.medicare === "") ||
                (this.completedSteps ===
                    MEDICARE_FORM_LENGTH - TWO_FORM_STEP &&
                    GENERAL_FLOW && this.medicare === "medicare") ||
                (this.completedSteps ===
                    HEALTHCARE_FORM_LENGTH - TWO_FORM_STEP &&
                    GENERAL_FLOW && this.medicare === "")
            ) {
                //check for erros in address form
                if (this.errorAddAndCityForm()) {
                    this.completedSteps += ONE_FORM_STEP;
                    event.target.id = "step" + this.completedSteps;
                }

                //if city input empty
                if (!this.model.city) {
                    this.model.errorcity = true;
                }

                //if zip input empty
                if (!this.model.zip) {
                    this.model.errorzip = true;
                }
                
                return;
            }

            //if here not done or needing to
            //skip a step / do anything special
            //so just go up one step
            this.completedSteps += ONE_FORM_STEP;
            event.target.id = "step" + this.completedSteps;
        },
        validateInfo: function(e) {
            //reset name errors
            this.model.errorlast = false;
            this.model.errorfirst = false;

            //validate first name
            if (
                this.model.first_name == "" ||
                this.model.first_name.length < 2
            ) {
                this.model.errorfirst = true;
            }

            //validate last name
            if (this.model.last_name == "" || this.model.last_name.length < 2) {
                this.model.errorlast = true;
            }

            //email
            if (this.model.email == "") {
                this.model.erroremail = true; //empty email
            } else {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.model.erroremail = false;

                if (!re.test(this.model.email)) {
                    this.model.erroremail = true; //incorrectly formatted email
                }
            }

            //empty phone
            if (this.model.phone1 != "" && this.model.phone1.length == 3) {
                this.model.errorphone1 = false;
            } else {
                this.model.errorphone1 = true;
            }

            if (this.model.phone2 != "" && this.model.phone2.length == 3) {
                this.model.errorphone2 = false;
            } else {
                this.model.errorphone2 = true;
            }

            if (this.model.phone3 != "" && this.model.phone3.length == 4) {
                this.model.errorphone3 = false;
            } else {
                this.model.errorphone3 = true;
            }

            return (
                this.model.errorphone3 === false &&
                this.model.errorphone2 === false &&
                this.model.errorphone1 === false &&
                this.model.erroremail === false &&
                this.model.errorfirst === false &&
                this.model.errorlast === false
            );
        },
        inputValidation(value) {
            // validate value
            // console.log(value);
            const validatedValue = value.replace(/[^0-9]\./g, "");

            console.log(validatedValue);
            return validatedValue;
        },
        checkAndMoveToNext1(e) {
            this.clearPhone1();

            if (e.keyCode == BACKSPACE) return;

            var p1 = document.getElementById("phone1").value;
            document.getElementById("phone1").value = this.inputValidation(p1);
            var phone1Length = document.getElementById("phone1").value.length;

            if (phone1Length >= 3) {
                e.preventDefault();
            }

            if (phone1Length == 3) {
                this.model.phone1 = p1;
                var p2 = document.getElementById("phone2");
                p2.focus();
            }
        },
        checkAndMoveToNext2(e) {
            this.clearPhone2();

            if (e.keyCode == BACKSPACE) return;

            var p2 = document.getElementById("phone2").value;
            document.getElementById("phone2").value = this.inputValidation(p2);
            var phone2Length = document.getElementById("phone2").value.length;

            if (phone2Length >= 3) {
                e.preventDefault();
            }

            if (phone2Length == 3) {
                this.model.phone2 = p2;
                var p3 = document.getElementById("phone3");
                p3.focus();
            }
        },
        checkAndMoveToNext3(e) {
            this.clearPhone3();

            if (e.keyCode == BACKSPACE) return;

            var p3 = document.getElementById("phone3").value;

            document.getElementById("phone3").value = this.inputValidation(p3);
            var phone3Length = document.getElementById("phone3").value.length;

            if (phone3Length >= 4) {
                var btn =
                    document.getElementById("step" + MEDICARE_FORM_LENGTH) ||
                    document.getElementById("step" + HEALTHCARE_FORM_LENGTH);
                btn.focus();
                e.preventDefault();
            }
        },
        validateIncomeOnCreate: function(e) {
            for (var i = 0; i < this.formValues.incomes.length; i++) {
                if (this.formValues.incomes[i] == income) {
                    return true;
                }
            }
            return false;
        },
        validateHouseholdOncreate: function(e) {
            // alert(this.formValues.households);
            for (var i = 0; i < this.formValues.households.length; i++) {
                if (this.formValues.households[i] == household) {
                    return true;
                }
            }
            return false;
        },
        validateGenderOncreate: function(e) {
            if (this.model.gender == "M" || this.model.gender == "F")
                return true;
            return false;
        },
        validateDobOncreate: function(e) {
            if (this.validateDobDayOnCreate()) {
                this.model.dob_day = "DD";
            }
            if (this.validateDobMonthOnCreate()) {
                this.model.dob_month = "MM";
            }
            if (this.validateDobYearOnCreate()) {               
                    this.model.dob_year = "YYYY";
            }
            if (this.model.dob_day != "DD" && this.model.dob_month != "MM") {
                return true;
            }
            return false;
        },
        validateDobDayOnCreate: function(e) {
            for (var i = 1; i < this.formValues.days.length; i++) {
                if (this.formValues.days[i] == dobday) {
                    return false;
                }
            }
            return true;
        },
        validateDobMonthOnCreate: function(e) {
            for (var i = 1; i < this.formValues.months.length; i++) {
                if (this.formValues.months[i] == dobmonth) {
                    return false;
                }
            }
            return true;
        },
        validateDobYearOnCreate: function(e) {
            for (var i = 0; i < this.formValues.years.length; i++) {
                if (this.formValues.years[i] == dobyear) {
                    return false;
                }
            }
            return true;
        },
        validateDate: function(e) {
            if (this.flow == "medicare") {
                return true;
            }
            if (this.model.dob_day != "DD") {
                this.model.errorday = false;
            }

            if (this.model.dob_month != "MM") {
                this.model.errormonth = false;
            }

            if (this.model.dob_day != "DD" && this.model.dob_month != "MM") {
                return true;
            }

            if (this.model.dob_day == "DD") {
                this.model.errorday = true;
            }
            if (this.model.dob_month == "MM") {
                this.model.errormonth = true;
            }

            return false;
        },
        backStep: function(e) {
            this.transitionName = "bounce1";

            //go down a step
            this.completedSteps -= ONE_FORM_STEP;
            changeButtonId(this.completedSteps);

            //go down another if the user skipped
            //going up
            if (
                this.stepAmount == 2 &&
                this.completedSteps == 3 &&
                this.flow == "medicare"
            ) {
                this.completedSteps -= ONE_FORM_STEP;
                changeButtonId(this.completedSteps);
            }

            //?
            if (this.model.city == "") {
                this.model.city = this.model.citygoogle;
            }
        },
        showMedicareValue() {
            console.log(this.medicare);
        },
        cleanDob: function () {                       
            var dobMonth = this.model.dob_month
             if(dobMonth ==="MM"){
               dobMonth = "01"
             }
             var dobDay = this.model.dob_day
              if(dobDay ==="DD"){
               dobDay = "01"
             }             
             var dobYear =  this.model.dob_year             
             //if the DOB Year is YYYY this will never be a valid DOB, so return undefined so it doesn't cause date formatting issues with downstream tools.
             if (dobYear ==='YYYY'){
                 return undefined
                 }
             var dob = dobYear+"-"+dobMonth+"-"+dobDay                 
             return dob            
        },
        phoneValidationGet: async function() {
            const apiToken = '3c4e3923-04c9-4a5c-bfbd-ad9cfd88590e';
            const phoneNumber = "+1" + this.model.phone1 + this.model.phone2 + this.model.phone3
            var eOurl = "https://api.emailoversight.com/api/phonevalidation?apitoken=" + apiToken + "&phonenumber=" + phoneNumber;
            var vm = this
            try {
                const response = await axios.get(eOurl, {timeout:1500});
                 var str = JSON.stringify(response, null, 2);
                //Usable Json Object
                var obj = JSON.parse(str);
                vm.model.phoneValidation = obj.data;  
                console.log(vm.model.phoneValidation); 
            }
            catch (error) {
                console.log("ERROR:", error);
                 vm.model.phoneValidation = error;  
            }    
        },  
        addConnectMe: function(module1, module2, follow) {
            const cmContainer = document.getElementById('cmContainer');
            const conversion_id = new URL(location.href).searchParams.get('r');
            cmContainer.innerHTML= '<div class="g3cm_holder" data-module="' + module1 + '" data-conversionid="' + conversion_id +
            '" data-followup="' + follow + '" data-showoninit="true" style="display:none;"></div>' +
        '<div class="g3cm_holder" data-module="'+module2+'" data-showoninit="true" data-conversionid="'+conversion_id+'" style="display:none;"></div>';
        },
        gtag_report_conversion: function(url) {
            console.log('google conversion working')
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-574485969/_gLzCPLokboYENHr95EC',
                  'event_callback': callback
              });
              return false;
        },
        onComplete:  function (e) {            
          if (
                this.validateInfo(e) &&
                this.checkValidPhone(
                    this.model.phone1 + "-" + this.model.phone2
                )
            ) {
                if (window.dataLayer) {   
                    this.gtag_report_conversion();
                  // Add string according to GTM  
                    const buttonStep = this.flow + '-submitted';                    
                    console.log(buttonStep);                  
                    window.dataLayer.push({ event: buttonStep });

                    dataLayer.push({'event': 'dl - lead_converted',
                        'data':
                          {
                        'firstname':  this.model.first_name,
                        'lastname':  this.model.last_name,
                        'email':  this.model.email,
                        'zip_code':  this.model.zip,
                        'birthdate':  this.cleanDob(),
                        'gender': this.model.gender,
                        'address': this.model.address,
                        'city': this.model.city,
                        'state': this.model.state,
                        'phone_number': "+1" + this.model.phone1 + this.model.phone2 + this.model.phone3,
                        'household_size': this.model.household,
                        'household_income': this.model.income,
                        }
                        });
                }
                let tcpa =  document.getElementById("tcpa") ?  document.getElementById("tcpa") : '' ;         
                this.tcpa = tcpa.textContent;
                //console.log(this.tcpa);
                const module1 = 1776;
                const module2 = 1777;
                const follow = 1778;
                this.addConnectMe(module1, module2, follow); 
                this.renderThankYou(); 
              //  this.getTags();
                // disabled because it affected the sales
                //await this.phoneValidationGet();                
                this.getGeoInfo();

                this.postData(this.model);  

                this.completedSteps = 7;    
                if (checkConnectMe()) {
                    connectMe(this.model.phone1 +this.model.phone2 +this.model.phone3);
                    if (addDataDestinations()) {
                        console.log("data destinations was a go");
                    } else {
                        console.log("data destinations was a no go");
                    }
                }              
            } else if(this.checkValidPhone(
                    this.model.phone1 + "-" + this.model.phone2
                )
            ) {
                this.gtag_report_conversion();
                 const buttonStep = this.flow + '-nonsubmit';                    
                 console.log(buttonStep);                  
                 window.dataLayer.push({ event: buttonStep });

                const module1 = 1525;
                const module2 = 1587;
                const follow = 1603;
                this.addConnectMe(module1, module2, follow); 
            

                uet_report_conversion();

                if (addDataDestinations()) {
                    console.log("data destinations was a go");
                } else {
                    console.log("data destinations was a no go");
                }

                localStorage.setItem(
                    "phonenumber",
                    this.model.phone1 +
                        " " +
                        this.model.phone2 +
                        " " +
                        this.model.phone3
                );
                if (checkConnectMe()) {
                    connectMe(this.model.phone1 +this.model.phone2 +this.model.phone3);
                }
            }
        },
        getAge: function(birthDate, ageAtDate) {
            var daysInMonth = 30.436875; // Days in a month on average.
            var dob = new Date(birthDate);
            var aad;
            if (!ageAtDate) aad = new Date();
            else aad = new Date(ageAtDate);
            var yearAad = aad.getFullYear();
            var yearDob = dob.getFullYear();
            var years = yearAad - yearDob; // Get age in years.
            dob.setFullYear(yearAad); // Set birthday for this year.
            var aadMillis = aad.getTime();
            var dobMillis = dob.getTime();
            if (aadMillis < dobMillis) {
                --years;
                dob.setFullYear(yearAad - 1); // Set to previous year's birthday
                dobMillis = dob.getTime();
            }
            var days = (aadMillis - dobMillis) / 86400000;
            var monthsDec = days / daysInMonth; // Months with remainder.
            var months = Math.floor(monthsDec); // Remove fraction from month.
            days = Math.floor(daysInMonth * (monthsDec - months));
            return { years: years, months: months, days: days };
        },
        setzipformation: function(info) {
            this.model.city = info.city;
            this.model.citygoogle = info.city;
            this.model.state = info.state;
        },
        next: function() {
            this.model.zip = this.getQueryVariable("zipcode");
        },
        setaddressformation: function(address) {
            this.model.address = address;
        },

        setlocationinformation: function(info) {
            this.model.city = info.city;
            this.model.state = info.state;
            this.model.address = info.address;
        },
        renderThankYou: function() {
            document.getElementById("progress-wrapper").className = "hide";

            if (this.medicare == "medicare") {
                document.getElementById("buttond-holder-1").className = "hide";
            } else {
                document.getElementById("buttond-holder").className = "hide";
            }         

            // Everflow conversion
              /*  EF.conversion({
                    offer_id: EF.urlParameter('oid'),
                });*/


            document.getElementById("header-desk").innerHTML =
                "Thank You! <br/><br/> A Licensed Insurance Agent will be reaching out shortly to check your eligibility to enroll and review your options.";
            document.getElementById("header-mobile").innerHTML =
                "Thank You! <br/><br/> A Licensed Insurance Agent will be reaching out shortly to check your eligibility to enroll and review your options.";

            var zip = "";
            var aff = "";
            var rid = "";
            var s1 = this.model.source1;
            var s2 = this.model.source2;

            rid = this.model.requestid;

            if (this.model.zip == "undefined" || this.model.zip == "") {
                zip = "99999";
            } else {
                zip = this.model.zip;
                //console.log(zip);
            }

            aff = this.model.affiliate;

            var bday =
                this.model.dob_year +
                "-" +
                this.model.dob_month +
                "-" +
                this.model.dob_day;

            var age = this.getAge(bday);

            let showme = this.model.showme;
            let show = "QS"; //10/19/21 5:18pm pst qs 100%
            // let rand = Math.floor(Math.random() * Math.floor(1000));

            let rand = 900;

            if (showme == "QS") {
                show = "QS";
            } else if (showme == "MA") {
                show = "MA";
            } else {
                if (rand <= 900) {
                    show = "MA";
                } else {
                    show = "QS";
                }
            }

            let clicklist = "";

            var premium = [543, 544, "a", 240];
            var internal = [545, 542, 559, 179];

            switch (show) {
                case "MA":
                    console.log("MA shown");
                    if (age.years) {
                        console.log("age = ", age.years);
                        if (age.years >= 65) {
                            //Show Med Supp if older than 64.5

                            clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";

                            if (premium.includes(aff)) {
                                clicklist = "MAfr0rhZFRXFwDgiHhBC3Y7kCQjfiw";
                            } else if (internal.includes(aff)) {
                                clicklist = "IcmVWSgdowOFpDmwgwi_emkMeZhKdQ";
                            } else {
                                clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";
                            }
                        } else {
                            //Default U65
                            clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";

                            if (premium.includes(aff)) {
                                clicklist = "x30E_wy_fbHiPsE7xLoRh1_3bL8VsQ";
                            } else if (internal.includes(aff)) {
                                clicklist = "OXVTE9_7z-K_NC_wOrP277vINTyyhA";
                            } else {
                                clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";
                            }
                        }
                    } else {
                        console.log("flow = ", this.flow);
                        if (this.flow == "medicare") {
                            //Show Med Supp if older than 64.5

                            clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";

                            if (premium.includes(aff)) {
                                clicklist = "MAfr0rhZFRXFwDgiHhBC3Y7kCQjfiw";
                            } else if (internal.includes(aff)) {
                                clicklist = "IcmVWSgdowOFpDmwgwi_emkMeZhKdQ";
                            } else {
                                clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";
                            }
                        } else {
                            //Default U65
                            clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";

                            if (premium.includes(aff)) {
                                clicklist = "x30E_wy_fbHiPsE7xLoRh1_3bL8VsQ";
                            } else if (internal.includes(aff)) {
                                clicklist = "OXVTE9_7z-K_NC_wOrP277vINTyyhA";
                            } else {
                                clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";
                            }
                        }
                    }
                    //script from thanks page - Media Alpha

                    localStorage.setItem("clicklist", clicklist);
                    localStorage.setItem("s1", s1);
                    localStorage.setItem("s2", rid);
                    localStorage.setItem("a", aff);
                    localStorage.setItem("zip", zip);
                    localStorage.setItem("gender", this.model.gender);
                    localStorage.setItem(
                        "birth_date",
                        this.model.dob_year +
                            "-" +
                            this.model.dob_month +
                            "-" +
                            this.model.dob_day
                    );
                    localStorage.setItem("income", this.model.income);

                    loadAdUnit();

                    break;
                case "QS":
                    this.ten = true;

                    console.log("QS shown");

                    if (age.years) {
                        console.log("age = ", age.years);
                        console.log("flow = ", this.flow);
                        if (age.years >= 65) {
                            if (premium.includes(aff)) {
                                clicklist = 665509;
                            } else if (internal.includes(aff)) {
                                clicklist = 665510;
                            } else {
                                clicklist = 663719;
                            }
                        } else {
                            if (premium.includes(aff)) {
                                clicklist = 665512;
                            } else if (internal.includes(aff)) {
                                clicklist = 665513;
                            } else {
                                clicklist = 663716;
                            }
                        }
                    } else {
                        console.log("flow = ", this.flow);
                        if (this.flow == "medicare") {
                            if (premium.includes(aff)) {
                                clicklist = 665509;
                            } else if (internal.includes(aff)) {
                                clicklist = 665510;
                            } else {
                                clicklist = 663719;
                            }
                        } else {
                            if (premium.includes(aff)) {
                                clicklist = 665512;
                            } else if (internal.includes(aff)) {
                                clicklist = 665513;
                            } else {
                                clicklist = 663716;
                            }
                        }
                    }

                    var matchingConfiguration = {
                        src: clicklist,
                        trn_id: "transactionId",
                        leadid: "",
                        zipcode: zip,
                        var1: aff,
                        var2: s1,
                        var3: rid,
                        householdsize: this.model.household,
                        age: age.years || 65,
                        gender: this.model.gender
                    };

                    if (this.flow === "healthcare") {
                        matchingConfiguration.income = this.model.income;
                    }

                    localStorage.setItem(
                        "matchingConfigurationLocal",
                        JSON.stringify(matchingConfiguration)
                    );
                    loadQS();

                    console.log(clicklist);

                    break;
                default:
                    break;
            }
        },
        getGeoInfo: function() {
            var current = this;

            //production staging url
             let backendURL = "https://gorgeous-jakarta-bmrwwnrr8b1p.vapor-farm-c1.com/api/checkzip";

            //backend staging url
            // let backendURL = "https://resilient-night-enwldlt94wk1.vapor-farm-c1.com/api/checkzip";

            //FOR LOCAL TESTING ONLY
            //let backendURL = "http://127.0.0.1:8001/api/checkzip";

            $.ajax({
                type: "POST",
                url: backendURL,
                data: {
                    zip: zip
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    if (xhr.status == 404) {
                        console.log(thrownError);
                    }
                },
                success: function(res) {
                    //console.log(res);

                    //set state and short name
                    var state = res.data.stateShortName; //Needs to be shortname
                    var city = res.data.cityName;

                    var info = {
                        city,
                        state
                    };
                    current.setzipformation(info);
                }
            });
        },
        validateDOBTab: function() {
            //            var out = this.$refs.dobTabForm.validate();

            var out = this.validateDate();
            return out;
        },
        validateSecondTab: function() {
            var out = this.$refs.secondTabForm.validate();
            return out;
        },
        validateThirdTab: function() {
            this.last = true;
            this.back = "";
            return true;
        },
        validateFirstTab: function() {
            var out = this.$refs.thirdTabForm.validate();
            console.log(out);
            return out;
            //            return true;
        },
        postData: async function(data) {

            // Start Everflow Pixel
                    let urlPath2 =
                    "https://www.hqudkd2.com/?nid=1561&transaction_id=" +
                    data.requestid + '&adv1=' + await this.sha256(data.phone1 + data.phone2 + data.phone3 )+ '&adv2=' + await this.sha256(data.email) + '&adv3=' + await this.sha256(data.ip_address) 
                        var everflowPix = document.getElementById("everflowPix");                  
                        everflowPix.innerHTML =
                        '<iframe src="' +
                        urlPath2 + 
                        '" height="1" width="1" frameborder="0"></iframe>';
                   console.log('everflowpix added');
                  
            //End Everflow Pixel

            const universal_leadid_el = document.getElementById("leadid_token");
            const universal_leadid_v = universal_leadid_el
                ? universal_leadid_el.value
                : "";

            const trusted_form_token_el = document.getElementById(
                "xxTrustedFormToken_0"
            );
            const trusted_form_token_v = trusted_form_token_el
                ? trusted_form_token_el.value
                : "";

            const trusted_form_url_el = document.getElementById(
                "xxTrustedFormCertUrl_0"
            );
            const trusted_form_url_v = trusted_form_url_el
                ? trusted_form_url_el.value
                : "";

            let rand = this.getRandomInt(1000);
            let affiliate = data.affiliate;
            let zipcode = data.zip;
            let gender = data.gender;
            let req = data.requestid;
            let bday =
                data.dob_year + "-" + data.dob_month + "-" + data.dob_day;
            let income = data.income;
            let s5 = data.source5;

            let ecakeid = "33";

            //production staging url
            let backendURL = "https://gorgeous-jakarta-bmrwwnrr8b1p.vapor-farm-c1.com/api/lead";

            //backend staging url
            // let backendURL = "https://resilient-night-enwldlt94wk1.vapor-farm-c1.com/api/lead";
            // let backendURL = "https://back.massive.net/api/lead";
            //FOR LOCAL TESTING ONLY
            // let backendURL = "http://127.0.0.1:8000/api/lead";
            
            // data.address = "54 test";
            // data.dob_day = "01";
            // data.dob_month = "01";

            const body = {
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone1 + " " + data.phone2 + " " + data.phone3,
                address: data.address,
                city: data.city,
                state: data.state,
                email: data.email,
                year: data.dob_year,
                month: data.dob_month,
                day: data.dob_day,
                zip: data.zip || "98111",
                ip_address: data.ip_address,
                ip_address_error: data.ip_address_error,
                gender: data.gender,
                tcpa:this.tcpa,
                // currentlyEnrolled: data.currentlyEnrolled,
                tobacco: data.tobacco,
                self_employed: data.self_employed,
                feet: data.feet,
                weight: data.weight,
                pregnant: data.pregnant,
                medical: data.medical,
                universal_leadid: universal_leadid_v,
                trusted_form_token: trusted_form_token_v,
                trusted_form_url: trusted_form_url_v,
                trusted_form_ping: document.getElementById('xxTrustedFormPingUrl_0')?.value,
                source: data.source,
                affiliate: data.affiliate,
                creative: data.creative,
                redirect_db: data.redirect_db + "/" + this.flow,
                requestid: data.requestid,
                oid: data.oid,
                is_robly: data.is_robly,
                offer: data.offer,
                tfn: data.tfn,
                arcamax_signup: data.arcamax_signup,
                boberdoo_src: boberdooSrc,
                income: data.income,
                household: data.household,
                source1: data.source1,
                source2: data.source2,
                source3: data.source3,
                source4: data.source4,
                source5: data.source5,
                test_lead: "0",
                ecake: ecakeid,
                flow: this.flow,
                site: "smc",
                biden: false, //always false as biden in hcm
                phoneValidation: data.phoneValidation
            };

            try {
                const response = await axios.post(backendURL, body);

                /*
                let urlPath =
                    "https://rdtrker03.com/p.ashx?o=" +
                    data.oid +
                    "&e=33&fb=1&r=" +
                    data.requestid +
                    "&t=TRANSACTION_ID";

                var cakepix = document.getElementById("cakepix");

                cakepix.innerHTML =
                    '<iframe src="' +
                    urlPath +
                    '" height="1" width="1" frameborder="0"></iframe>';*/
                    
                console.log("validation response: " + JSON.stringify(response.data));
                console.log("lead saved to db");
                
                
            } catch (error) {
                console.log("ERROR:", error);
            }
        },
        getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        },
        prettyJSON: function(json) {
            /*if (json) {
                 json = JSON.stringify(json, undefined, 4);
                 json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
                 return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                     var cls = 'number';
                     if (/^"/.test(match)) {
                         if (/:$/.test(match)) {
                             cls = 'key';
                         } else {
                             cls = 'string';
                         }
                     } else if (/true|false/.test(match)) {
                         cls = 'boolean';
                     } else if (/null/.test(match)) {
                         cls = 'null';
                     }
                     return '<span class="' + cls + '">' + match + '</span>';
                 });
             }*/

            console.log(json);
        }
    }
});
function changeButtonId(value) {
    $(".nextbutton").attr("id", "step" + value);
}
function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
}
