<template>
    <radial-progress-bar
        :diameter="90"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        :stop-color="color"
        :start-color="color"
        :is-clockwise="true"
        :inner-stroke-width="5"
        :inner-stroke-color="'#cccccc'"
        :stroke-width="5"
    >
        <div class="percent text-center">
            {{ getPercent(completedSteps) }}
            <div class="completed">
                {{ name }}
            </div>
        </div>
    </radial-progress-bar>
</template>
<script>
import RadialProgressBar from "vue-radial-progress";
export default {
    methods: {
        getPercent(completedSteps) {
            let step = this.stepValue / this.totalSteps;
            let value =
                completedSteps == 0 ? 0 : Math.ceil(step * completedSteps);
            if (value == 60) {
                value = 59;
            }
            return value + "";
        }
    },
    components: {
        RadialProgressBar
    },
    props: {
        completedSteps: {
            type: Number,
            default: 0
        },
        totalSteps: {
            type: Number,
            default: 0
        },
        stepValue: {
            type: Number
        },
        name: {
            type: String
        },
        color: {
            type: String
        }
    }
};
</script>
<style scoped>
.percent {
    font-size: 25px;
    font-weight: 600;
    margin-top: -9px;
}
.completed {
    font-weight: 600;
    margin-left: 1px;
    font-size: x-small;
    margin-top: -7px;
}
</style>
